define("securitytrax-ember-ui/components/multi-step-form/forms/customer/create-financing/smart-home-pay-los/create-application/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/components/multi-step-form/forms/customer/create-financing/smart-home-pay-los/smart-home-pay-los-base", "moment"], function (_exports, _emberConcurrency, _smartHomePayLosBase, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var SmartHomePayLosCreateApplication = (_dec = Ember.inject.service, _dec2 = (0, _emberConcurrency.task)(function* () {
    yield (0, _emberConcurrency.waitForProperty)(this.args.multiStepForm, 'finishedCopyingModels', true);

    if (!this.args.multiStepForm.formCopiedModelsGetVal('consumerFinancingApplication.model.id')) {
      var _this$args$customer$g;

      var hashedData = yield (0, _emberConcurrency.hash)({
        customerBirthdate: this.args.customer.get('customerBirthdate'),
        waitForFinishedCopyModels: (0, _emberConcurrency.waitForProperty)(this.args.multiStepForm, 'finishedCopyingModels', true)
      });
      this.args.multiStepForm.formCopiedModelsSetVal('consumerFinancingApplication.model.consumerFinancingApplicant', Ember.Object.create({
        customer: this.args.customer,
        fname: this.args.customer.get('fname'),
        lname: this.args.customer.get('lname'),
        address1: this.args.customer.get('address1'),
        address2: this.args.customer.get('address2'),
        city: this.args.customer.get('city'),
        state: this.args.customer.get('state'),
        zip: this.args.customer.get('zip'),
        email: this.args.customer.get('email'),
        cellPhone: (_this$args$customer$g = this.args.customer.get('cellPhone')) !== null && _this$args$customer$g !== void 0 ? _this$args$customer$g : this.args.customer.get('primaryPhone'),
        birthdate: this.grants.getIndividualGrant('customer_birthdates.view') ? hashedData.customerBirthdate.get('birthdate') : null,
        enumLists: this.args.multiStepForm.formCopiedModelsGetVal('consumerFinancingApplication.model.consumerFinancingApplicant.enumLists')
      }));
    } else if ((0, _moment.default)(this.args.multiStepForm.formCopiedModelsGetVal('consumerFinancingApplication.model.consumerFinancingApplicant.birthdate')).format('MM-DD-YYYY') === '01-01-0001') {
      this.args.multiStepForm.formCopiedModelsSetVal('consumerFinancingApplication.model.consumerFinancingApplicant.birthdate', null);
    }
  }), _dec3 = (0, _emberConcurrency.task)(function* () {
    var application = this.args.multiStepForm.formCopiedModelsGetVal('consumerFinancingApplication.model'); //DB won't let birthdate be null or '' so we have to use a placeholder as a "blank" date (or else update DB schema)

    if (!this.args.multiStepForm.formCopiedModelsGetVal('consumerFinancingApplication.model.consumerFinancingApplicant.birthdate')) {
      this.args.multiStepForm.formCopiedModelsSetVal('consumerFinancingApplication.model.consumerFinancingApplicant.birthdate', '01-01-0001');
    }

    var applicant = this.args.multiStepForm.formCopiedModelsGetVal('consumerFinancingApplication.model.consumerFinancingApplicant');
    var formattedObjects = [];
    formattedObjects.push(Ember.Object.create({
      modelName: 'consumerFinancingApplicant',
      model: applicant,
      extraValidationData: {
        noValidate: this.applicantNoValidate
      },
      filePath: 'consumer-financing-applicant'
    }));
    formattedObjects.push(Ember.Object.create({
      model: application,
      modelName: 'consumerFinancingApplication',
      filePath: 'consumer-financing-application',
      extraValidationData: {
        noValidate: this.applicationNoValidate
      }
    })); //validate applicant and application

    var toCreateObjects = yield this.args.multiStepForm.validateCreateList.perform(formattedObjects, {
      notificationNamespace: 'modal'
    });
    var savedRecords = []; //create and save applicant

    var createdApplicant = this.store.createRecord('consumerFinancingApplicant', toCreateObjects['consumerFinancingApplicant'][0].data);
    var savedApplicant = yield toCreateObjects['consumerFinancingApplicant'][0].saveWithRollbackAndNotification(createdApplicant, 'modal');
    savedRecords.push(savedApplicant); //create and save application

    var hashedData = yield (0, _emberConcurrency.hash)({
      lender: this.store.findRecord('consumerFinancingLender', this.args.lenderId),
      applicant: this.store.findRecord('consumerFinancingApplicant', savedApplicant.get('id')),
      coapplicant: null
    });
    this.args.multiStepForm.formCopiedModelsSetVal('consumerFinancingApplication.model.consumerFinancingLender', hashedData.lender);
    this.args.multiStepForm.formCopiedModelsSetVal('consumerFinancingApplication.model.consumerFinancingApplicant', hashedData.applicant);
    this.args.multiStepForm.formCopiedModelsSetVal('consumerFinancingApplication.model.consumerFinancingCoapplicant', hashedData.coapplicant);
    var createdApplication = this.store.createRecord('consumerFinancingApplication', application);
    var savedApplication = yield toCreateObjects['consumerFinancingApplication'][0].saveWithRollbackAndNotification(createdApplication, 'modal');
    savedRecords.push(savedApplication);
    this.args.multiStepForm.formOriginalModelsSetVal('consumerFinancingApplication.model', savedApplication);
    yield this.args.multiStepForm.setupCopyModels.perform();
    return savedApplication;
  }), _dec4 = (0, _emberConcurrency.task)(function* () {
    this.args.multiStepForm.formCopiedModelsSetVal('consumerFinancingApplication.model.consumerFinancingApplicant.ssn', null); //DB won't let birthdate be null or '' so we have to use a placeholder as a "blank" date (or else update DB schema)

    if (!this.args.multiStepForm.formCopiedModelsGetVal('consumerFinancingApplication.model.consumerFinancingApplicant.birthdate')) {
      this.args.multiStepForm.formCopiedModelsSetVal('consumerFinancingApplication.model.consumerFinancingApplicant.birthdate', '01-01-0001');
    }

    yield this.args.multiStepForm.validateSyncSaveAll({
      overrideOriginalModelsConfig: Ember.Object.create({
        consumerFinancingApplication: {
          doSave: true,
          copyRelationMap: {
            consumerFinancingApplicant: {
              doSave: true
            }
          }
        }
      }),
      extraValidationData: {
        noValidate: this.applicationNoValidate.concat(this.applicantNoValidate)
      },
      notificationNamespace: 'modal'
    });
    return yield this.store.findRecord('consumerFinancingApplication', this.args.multiStepForm.formCopiedModelsGetVal('consumerFinancingApplication.model.id'));
  }), _dec5 = (0, _emberConcurrency.task)(function* () {
    var application;

    if (this.args.multiStepForm.formCopiedModelsGetVal('consumerFinancingApplication.model.id')) {
      application = yield this.saveApplicationTask.perform();
    } else {
      application = yield this.createApplicationTask.perform();
    }

    this.stNotificationManager.addSuccessSave('modal');
    this.args.customer.get('consumerFinancingApplications').reload();

    try {
      yield application.invokeAction('submit_application', {
        verb: 'POST',
        data: {
          data: {}
        }
      });
    } catch (err) {
      console.log(err);
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });
      throw err;
    }

    this.stNotificationManager.addSuccess(this.intl.t('pages.customers.accounting.financing.application_submitted'), 'global');
    this.args.multiStepForm.formStartedSetVal(false);
    this.args.onSubmit(application);
  }), _dec6 = Ember._action, (_class = (_temp = class SmartHomePayLosCreateApplication extends _smartHomePayLosBase.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "usStates", _descriptor, this);

      _initializerDefineProperty(this, "initDataTask", _descriptor2, this);

      _initializerDefineProperty(this, "createApplicationTask", _descriptor3, this);

      _initializerDefineProperty(this, "saveApplicationTask", _descriptor4, this);

      _initializerDefineProperty(this, "submitApplicationTask", _descriptor5, this);

      this.initDataTask.perform();
    }

    get grossAnnualIncome() {
      return this.intl.formatNumber(this.args.multiStepForm.formCopiedModelsGetVal('consumerFinancingApplication.model.consumerFinancingApplicant.grossMonthlyIncome') * 12, {
        style: 'currency',
        currency: this.intl.t('common.currency'),
        fallback: this.intl.t('common.zero_currency')
      });
    }

    get applicationNoValidate() {
      var applicationNoValidate = ['consumerFinancingApplication-completionDate', 'consumerFinancingApplication-contractState', 'consumerFinancingApplication-productOrService', 'consumerFinancingApplication-salesTaxRate', 'consumerFinancingApplication-sellingPrice', 'consumerFinancingApplication-creditAmountRequested'];
      return applicationNoValidate;
    }

    get applicantNoValidate() {
      var applicantNoValidate = ['consumerFinancingApplicant-employer', 'consumerFinancingApplicant-employerStartDate', 'consumerFinancingApplicant-employmentType', 'consumerFinancingApplicant-homeValue', 'consumerFinancingApplicant-idExpirationDate', 'consumerFinancingApplicant-idIssueDate', 'consumerFinancingApplicant-idNumber', 'consumerFinancingApplicant-idStateOn', 'consumerFinancingApplicant-idType', 'consumerFinancingApplicant-occupation', 'consumerFinancingApplicant-residenceMonthlyPayment', 'consumerFinancingApplicant-residenceType', 'consumerFinancingApplicant-ssn', 'consumerFinancingApplicant-verifySsn', 'consumerFinancingApplicant-address1', 'consumerFinancingApplicant-city', 'consumerFinancingApplicant-state', 'consumerFinancingApplicant-zip', 'consumerFinancingApplicant-cellPhone', 'consumerFinancingApplicant-birthdate', 'consumerFinancingApplicant-grossMonthlyIncome'];
      return applicantNoValidate;
    }

    buttons() {
      var buttons = [];
      buttons.push({
        text: this.intl.t('common.actions.submit'),
        click: () => {
          return this.submitApplicationTask.perform();
        },
        classNamesString: 'st-btn st-btn-primary'
      });
      this.args.multiStepForm.registerStepData('createApplication', 'buttons', buttons);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "usStates", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "initDataTask", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "createApplicationTask", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "saveApplicationTask", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "submitApplicationTask", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "buttons", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "buttons"), _class.prototype)), _class));
  _exports.default = SmartHomePayLosCreateApplication;
});