define("securitytrax-ember-ui/components/multi-step-form/forms/customer-interactive-services-provider-information/adc/re-usable-forms/add-ons/component", ["exports", "ember-concurrency", "lodash"], function (_exports, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    ispService: Ember.inject.service(),
    adcEvents: Ember.inject.service('events/adc-events'),
    packageId: undefined,
    panelId: undefined,
    customerOrCommitmentId: undefined,
    yieldedPackages: undefined,
    selectedAccessControl: undefined,
    selectedAccessControlBundle: undefined,
    initDataTask: (0, _emberConcurrency.task)(function* () {
      this.set('panelId', this.panelType);
      this.set('packageId', this.package);
      this.set('customerOrCommitmentId', this.customerOrCommitment);
      Ember.set(this, 'yieldedPackages', yield this.packages);
      yield this.getOptionsLists.perform();
      yield this.updateAddonsTask.perform(false);

      if (this.formMode === 'edit') {
        var videoPackagesArray = ['BasicDoorbell', 'SingleDoorbellWithAnalytics', 'PremiumVideo', 'ProVideo', 'CommercialVideo8', 'CommercialVideo16', 'ProVideoWithAnalytics'];

        var interesectingArray = _lodash.default.intersection(this.addOns, videoPackagesArray);

        this.set('selectedVideoPackage', interesectingArray[0]);
        var accessControlPackagesArray = ['AccessControl', 'SmarterAccessControlPlus'];

        var interesectingAccessControlArray = _lodash.default.intersection(this.addOns, accessControlPackagesArray);

        this.set('selectedAccessControl', interesectingAccessControlArray[0]);
        var accessControlBundlePackagesArray = ['AccessControl16', 'AccessControlPlus16', 'AccessControl32', 'AccessControlPlus32', 'AccessControl64', 'AccessControlPlus64'];

        var interesectingAccessControlBundleArray = _lodash.default.intersection(this.addOns, accessControlBundlePackagesArray);

        this.set('selectedAccessControlBundle', interesectingAccessControlBundleArray[0]);
      }

      this.adcEvents.on('update-selected-video-package', this, '_videoPackageEventCallback');
      this.adcEvents.on('update-addons', this, '_updateAddonsCallback');
    }).on('init'),

    willDestroy() {
      this.adcEvents.off('update-selected-video-package', this, '_videoPackageEventCallback');
      this.adcEvents.off('update-addons', this, '_updateAddonsCallback');
    },

    _updateAddonsCallback(property, id) {
      this.set(property, id);
      this.updateAddonsTask.perform();
    },

    _videoPackageEventCallback(videoPackage, template = null) {
      this.set('packageId', videoPackage);
      this.set('template', template);

      if (this.customerAccountType === 'Video') {
        // id 245 pro video with analytics
        // id 253 commercial video 8
        // id 254 commercial video 16
        // id 273 Premium video
        // id 179 Pro video
        switch (this.packageId) {
          case 245:
            this.set('selectedVideoPackage', 'ProVideoWithAnalytics');
            break;

          case 253:
            this.set('selectedVideoPackage', 'CommercialVideo8');
            break;

          case 254:
            this.set('selectedVideoPackage', 'CommercialVideo16');
            break;

          case 273:
            this.set('selectedVideoPackage', 'PremiumVideo');
            break;

          case 179:
            this.set('selectedVideoPackage', 'ProVideo');
            break;
        }
      }
    },

    get packageOption() {
      return this.yieldedPackages.findBy('id', this.packageId);
    },

    getOptionsLists: (0, _emberConcurrency.task)(function* () {
      var hashedData = yield (0, _emberConcurrency.hash)({
        svrOptions: this.ispService.get('getIspListTask').perform({
          ispId: this.get('interactiveServicesProvider.id'),
          ispType: 'adc',
          list: 'add_on_video_twenty_four_seven'
        }),
        extraStorageList: this.ispService.get('getIspListTask').perform({
          ispId: this.get('interactiveServicesProvider.id'),
          ispType: 'adc',
          list: 'get_standard_video_expansion_packs'
        }),
        extraStorage8List: this.ispService.get('getIspListTask').perform({
          ispId: this.get('interactiveServicesProvider.id'),
          ispType: 'adc',
          list: 'get_commercial_video_8_expansions'
        }),
        extraStorage16List: this.ispService.get('getIspListTask').perform({
          ispId: this.get('interactiveServicesProvider.id'),
          ispType: 'adc',
          list: 'get_commercial_video_16_expansions'
        })
      });
      this.set('cachedSvrOptions', hashedData.svrOptions || []);
      this.set('cachedExtraStorageOptions', hashedData.extraStorageList ? hashedData.extraStorageList : []);
      this.set('cachedExtraStorage8Options', hashedData.extraStorage8List ? hashedData.extraStorage8List : []);
      this.set('cachedExtraStorage16Options', hashedData.extraStorage16List ? hashedData.extraStorage16List : []);
    }),
    includedAddons: Ember.computed('packageId', function () {
      var includedAddons = this.packageOption && this.packageOption.included_features ? this.packageOption.included_features : [];
      var includedAddonsMap = includedAddons.map(function (addon) {
        return addon.feature;
      });

      if (includedAddonsMap.includes('FiveNormalActivitySensors')) {
        this.set('activitySensorsToggle', true);
      } else {
        Ember.set(this, 'activitySensorsToggle', false);
      }

      if (includedAddonsMap.includes('ExtraSmarterBusinessTemperatureMonitoringSensors')) {
        this.set('extraSmarterBusinessTemperatureMonitoringSensorsToggle', true);
      } else {
        Ember.set(this, 'extraSmarterBusinessTemperatureMonitoringSensorsToggle', false);
      }

      return includedAddonsMap;
    }),
    chargedAddons: Ember.computed('packageId', function () {
      var chargedAddons = this.packageOption && this.packageOption.other_add_ons ? this.packageOption.other_add_ons : [];
      return chargedAddons.map(function (addon) {
        return addon.feature;
      });
    }),

    getInAddOnArrayCount(element) {
      var count = 0;
      var addOns = this.addOns;

      if (addOns && addOns.includes(element)) {
        this.get('addOns.[]').forEach(addOn => {
          if (addOn === element) {
            count++;
          }
        });
      }

      return count;
    },

    currentAddOns: Ember.computed('addOns.[]', function () {
      if (!this.addOns) {
        this.set('addOns', []);
      }

      return this.addOns;
    }),
    addOnArray: Ember.computed('currentAddOns.[]', function () {
      var arr = [];
      var addOns = this.currentAddOns;
      var dropdownAddOns = ['Video24x7PerSVR', 'TwoFiftyMBExtraVideoStorage', 'ImageSensorExtraUploads', 'FiveNormalActivitySensors', 'CommercialVideo8Expansion', 'CommercialVideo16Expansion', 'AccessControlPlusDoors', 'AccessControlDoors'];
      dropdownAddOns.forEach(dropdownAddOn => {
        arr[dropdownAddOn] = 0;
      });

      if (addOns) {
        addOns.forEach(addOn => {
          if (dropdownAddOns.includes(addOn)) {
            arr[addOn] = this.getInAddOnArrayCount(addOn);
          } else {
            arr[addOn] = true;
          }
        });
      }

      return arr;
    }),
    videoPackageOptions: Ember.computed('customerAccountType', 'packageId', function () {
      var packageOptions = [{
        value: 'ProVideo',
        name: this.intl.t('pages.customers.system.interactiveServicesProvider.addOns.videoMonitoring.proVideo')
      }, {
        value: 'ProVideoWithAnalytics',
        name: this.intl.t('pages.customers.system.interactiveServicesProvider.addOns.videoMonitoring.proVideoWithAnalytics')
      }, {
        value: 'PremiumVideo',
        name: this.intl.t('pages.customers.system.interactiveServicesProvider.addOns.videoMonitoring.premiumVideo')
      }];

      if (this.customerAccountType !== 'Video') {
        packageOptions.push({
          value: 'SingleDoorbellWithAnalytics',
          name: this.intl.t('pages.customers.system.interactiveServicesProvider.addOns.videoMonitoring.singleDoorbell')
        }, {
          value: 'BasicDoorbell',
          name: this.intl.t('pages.customers.system.interactiveServicesProvider.addOns.videoMonitoring.basicDoorbell')
        });
      } // if Commercial Package is selected


      if (this.packageId === 41 || this.packageId === 42) {
        packageOptions.push({
          value: 'CommercialVideo8',
          name: this.intl.t('pages.customers.system.interactiveServicesProvider.addOns.videoMonitoring.commercialVideo8')
        }, {
          value: 'CommercialVideo16',
          name: this.intl.t('pages.customers.system.interactiveServicesProvider.addOns.videoMonitoring.commercialVideo16')
        });
      }

      return packageOptions;
    }),
    accessControlOptions: Ember.computed(function () {
      return [{
        value: undefined,
        name: this.intl.t('pages.customers.system.interactiveServicesProvider.addOns.smarterAccessControl.noAccessControl')
      }, {
        value: 'AccessControl',
        name: this.intl.t('pages.customers.system.interactiveServicesProvider.addOns.smarterAccessControl.title')
      }, {
        value: 'SmarterAccessControlPlus',
        name: this.intl.t('pages.customers.system.interactiveServicesProvider.addOns.smarterAccessControl.smarterAccessControlPlus')
      }];
    }),
    accessControlBundleOptions: Ember.computed('selectedAccessControl', function () {
      return this.getAccessControlBundleOptionsBySelectedAccesControl(this.selectedAccessControl);
    }),
    accessControlDoorsOptions: Ember.computed('selectedAccessControlBundle', function () {
      var options = [];
      var accessControlPrefix = 'accessControl' + (this.selectedAccessControl === 'SmarterAccessControlPlus' ? 'Plus' : '');
      var selectedDoors = this.selectedAccessControlBundle ? Number(this.selectedAccessControlBundle.replace(accessControlPrefix.capitalize(), '')) : 0;

      for (var i = 0; i <= 68; i++) {
        options.push({
          value: i,
          name: i + ' ' + this.intl.t('pages.customers.system.interactiveServicesProvider.addOns.smarterAccessControl.door'),
          disabled: selectedDoors + i > 68
        });
      }

      return options;
    }),
    // SVRs
    video24x7Options: Ember.computed('interactiveServicesProvider.id', 'selectedVideoPackage', 'packageId', 'totalSvrs', function () {
      var svrOptionsList = this.cachedSvrOptions.map(option => option);
      var svrModifier = this.totalSvrs - this.addOnArray.Video24x7PerSVR || 0;
      svrOptionsList.every((option, index) => {
        if (index + svrModifier <= 10) {
          option.name = index + svrModifier + ' ' + this.intl.t('pages.customers.system.interactiveServicesProvider.addOns.videoMonitoring.svrs');
          return true;
        } else {
          svrOptionsList.splice(index, svrOptionsList.length);
          return false;
        }
      });
      return svrOptionsList;
    }),
    totalCamerasSelected: Ember.computed('addOnArray.{CommercialVideo8Expansion,CommercialVideo16Expansion,TwoFiftyMBExtraVideoStorage}', 'selectedVideoPackage', 'packageId', function () {
      var numOfCommercial8ExpansionsSelected = this.addOnArray.CommercialVideo8Expansion || 0;
      var numOfCommercial16ExpansionsSelected = this.addOnArray.CommercialVideo16Expansion || 0;
      var numOfStandardExpansionsSelected = this.addOnArray.TwoFiftyMBExtraVideoStorage || 0;
      var packageId = this.selectedVideoPackage;

      if (this.customerAccountType === 'Video') {
        packageId = this.packageId;
      }

      var baseCameraCount;

      switch (packageId) {
        case 'CommercialVideo8':
        case 253:
          baseCameraCount = 8;
          break;

        case 'CommercialVideo16':
        case 254:
          baseCameraCount = 16;
          break;

        default:
          baseCameraCount = 4;
      }

      return baseCameraCount + numOfCommercial8ExpansionsSelected * 8 + numOfCommercial16ExpansionsSelected * 16 + numOfStandardExpansionsSelected * 4;
    }),
    totalClips: Ember.computed('addOnArray.{CommercialVideo8Expansion,CommercialVideo16Expansion,TwoFiftyMBExtraVideoStorage}', 'selectedVideoPackage', 'packageId', function () {
      var numOfCommercial8ExpansionsSelected = this.addOnArray.CommercialVideo8Expansion || 0;
      var numOfCommercial16ExpansionsSelected = this.addOnArray.CommercialVideo16Expansion || 0;
      var numOfStandardExpansionsSelected = this.addOnArray.TwoFiftyMBExtraVideoStorage || 0;
      var packageId = this.selectedVideoPackage;

      if (this.customerAccountType === 'Video') {
        packageId = this.packageId;
      }

      var baseClipCount = ['CommercialVideo8', 'CommercialVideo16', 253, 254].includes(packageId) ? 5 : packageId === 'ProVideo' || packageId === 179 ? 1 : 3;
      return baseClipCount + numOfCommercial8ExpansionsSelected * 5 + numOfCommercial16ExpansionsSelected * 5 + numOfStandardExpansionsSelected * 5;
    }),
    totalSvrs: Ember.computed('addOnArray.{Video24x7PerSVR,CommercialVideo8Expansion,CommercialVideo16Expansion}', 'selectedVideoPackage', 'packageId', function () {
      var numOfSvrsExpansionsSelected = this.addOnArray.Video24x7PerSVR || 0;
      var numOfCommercial8ExpansionsSelected = this.addOnArray.CommercialVideo8Expansion || 0;
      var numOfCommercial16ExpansionsSelected = this.addOnArray.CommercialVideo16Expansion || 0;
      var packageId = this.selectedVideoPackage;

      if (this.customerAccountType === 'Video') {
        packageId = this.packageId;
      }

      var baseSvrCount = ['CommercialVideo8', 'CommercialVideo16', 253, 254].includes(packageId) ? 1 : 0;
      return baseSvrCount + numOfCommercial8ExpansionsSelected + numOfCommercial16ExpansionsSelected + numOfSvrsExpansionsSelected;
    }),
    extraStorageOptions: Ember.computed('interactiveServicesProvider.id', 'selectedVideoPackage', 'packageId', 'totalCamerasSelected', function () {
      var cameraModifier = this.totalCamerasSelected - (this.addOnArray.TwoFiftyMBExtraVideoStorage * 4 || 0);
      var extraStorageList = this.cachedExtraStorageOptions;
      extraStorageList.every((option, index) => {
        if (index * 4 + cameraModifier <= 40) {
          return true;
        } else {
          extraStorageList.splice(index, extraStorageList.length);
          return false;
        }
      });
      return extraStorageList;
    }),
    video8ExpansionPackOptions: Ember.computed('selectedVideoPackage', 'packageId', 'totalCamerasSelected', 'totalSvrs', 'totalClips', function () {
      var cameraModifier = this.totalCamerasSelected - (this.addOnArray.CommercialVideo8Expansion * 8 || 0);
      var svrModifier = this.totalSvrs - (this.addOnArray.CommercialVideo8Expansion * 1 || 0);
      var extraStorageList = this.cachedExtraStorage8Options;
      extraStorageList.every((option, index) => {
        if (index * 8 + cameraModifier <= 40 && index + svrModifier <= 10) {
          return true;
        } else {
          extraStorageList.splice(index, extraStorageList.length);
          return false;
        }
      });
      return extraStorageList;
    }),
    video16ExpansionPackOptions: Ember.computed('selectedVideoPackage', 'packageId', 'totalCamerasSelected', 'totalSvrs', 'totalClips', function () {
      var cameraModifier = this.totalCamerasSelected - (this.addOnArray.CommercialVideo8Expansion * 8 || 0);
      var svrModifier = this.totalSvrs - (this.addOnArray.CommercialVideo16Expansion * 1 || 0);
      var extraStorageList = this.cachedExtraStorage8Options;
      extraStorageList.every((option, index) => {
        if (index * 8 + cameraModifier <= 40 && index + svrModifier <= 10) {
          return true;
        } else {
          extraStorageList.splice(index, extraStorageList.length);
          return false;
        }
      });
      return extraStorageList;
    }),
    extraImageEventOptions: Ember.computed('interactiveServicesProvider.id', function () {
      return this.extraImageEventOptionsTask.perform();
    }),
    extraImageEventOptionsTask: (0, _emberConcurrency.task)(function* () {
      return yield this.ispService.get('getIspListTask').perform({
        ispId: this.get('interactiveServicesProvider.id'),
        ispType: 'adc',
        list: 'add_on_extra_image_events'
      });
    }),
    additionalSensorOptions: Ember.computed('interactiveServicesProvider.id', function () {
      return this.additionalSensorOptionsTask.perform();
    }),
    additionalSensorOptionsTask: (0, _emberConcurrency.task)(function* () {
      return yield this.ispService.get('getIspListTask').perform({
        ispId: this.get('interactiveServicesProvider.id'),
        ispType: 'adc',
        list: 'add_on_activity_sensors'
      });
    }),
    extraSmarterBusinessTemperatureMonitoringSensorOptions: Ember.computed('customer', function () {
      return this.extraSmarterBusinessTemperatureMonitoringSensorOptionsTask.perform();
    }),
    extraSmarterBusinessTemperatureMonitoringSensorOptionsTask: (0, _emberConcurrency.task)(function* () {
      return yield this.ispService.get('getIspListTask').perform({
        ispId: this.get('interactiveServicesProvider.id'),
        ispType: 'adc',
        list: 'get_extra_smarter_business_temperature_monitoring_sensors'
      });
    }),
    updateAddonsTask: (0, _emberConcurrency.task)(function* (resetAddons = true) {
      var hashedData = yield (0, _emberConcurrency.hash)({
        templates: this.templates,
        videoPackages: this.videoPackageOptions
      });
      var addOns = [];
      var templateOption = hashedData.templates.findBy('template_id', this.template);
      var includedAddons = this.packageOption && this.packageOption.included_features ? this.packageOption.included_features : [];
      var additionalAddons = templateOption && templateOption.add_on_features ? templateOption.add_on_features : [];
      var dropdownAddons = ['Video24x7PerSVR', 'TwoFiftyMBExtraVideoStorage', 'ImageSensorExtraUploads', 'FiveNormalActivitySensors', 'CommercialVideo8Expansion', 'CommercialVideo16Expansion', 'AccessControlPlusDoors', 'AccessControlDoors'];
      includedAddons.forEach(addon => {
        if ("".concat(addon.feature) in this.addOnDisplayConfig && this.addOnDisplayConfig[addon.feature] && !dropdownAddons.includes(addon.feature)) {
          addOns.push(addon.feature);

          if (!this.currentAddOns.includes(addon.feature)) {
            this.send('updateAddOns', addon.feature, false);
          }
        }
      });

      if (templateOption) {
        var videoPackageKeys = hashedData.videoPackages.map(videoPackage => {
          return videoPackage.value;
        });
        var videoPackage = additionalAddons.find(addon => {
          return videoPackageKeys.includes(addon.feature);
        });

        if (videoPackage) {
          this.set('selectedVideoPackage', videoPackage.feature);
        }

        additionalAddons.forEach(addon => {
          if ("".concat(addon.feature) in this.addOnDisplayConfig && this.addOnDisplayConfig[addon.feature] && !hashedData.videoPackages.findBy('value', addon.feature)) {
            if (dropdownAddons.includes(addon.feature)) {
              Ember.set(this.addOnArray, addon.feature, addon.max_quantity);
              this.actions.updateDropdownAddOns.call(this, addon.feature, {
                value: addon.max_quantity
              }, false);
            } else {
              addOns.push(addon.feature);

              if (!this.currentAddOns.includes(addon.feature)) {
                this.send('updateAddOns', addon.feature, false);
              }
            }
          }
        });
      }

      if (!templateOption && resetAddons) {
        this.set('selectedVideoPackage', null);
        this.set('addOns', addOns);
      }
    }),
    addOnDisplayConfig: Ember.computed('packageId', 'customerOrCommitmentId', 'customerAccountType', 'panelId', 'network', 'selectedVideoPackage', function () {
      // id 1 = Wireless Signal Forwarding
      // id 193 = Interactive Gold
      // id 208 = Interactive
      // id 209 = Interactive + Automation
      // id 41 = Commercial
      // id 42 = Commercial Plus
      // id 216 = Home Control and Awareness
      // video packages
      // id 245 pro video with analytics
      // id 253 commercial video 8
      // id 254 commercial video 16
      // id 273 Premium video
      // id 179 Pro video
      // id 180 Pro video plus
      // id 287 Commercial video 4
      // access control packages
      // id 269 Access control 16
      // id 270 Access control 32
      // id 271 Access control 64
      // id     Access control plus 16
      // id     Access control plus 32
      // id     Access control plus 64
      // id     Smarter access control
      // id     Smarter access control plus
      var configId = this.selectedVideoPackage;

      if (this.customerAccountType === 'Video') {
        configId = this.packageId;
      }

      var videoAddOnDisplayConfig = Ember.Object.create({
        TwoFiftyMBExtraVideoStorage: ['BasicDoorbell', 'SingleDoorbellWithAnalytics'].includes(configId) ? false : true,
        Video24x7PerSVR: ['BasicDoorbell', 'SingleDoorbellWithAnalytics'].includes(configId) ? false : true,
        DoorbellCameras: ['BasicDoorbell', 'SingleDoorbellWithAnalytics'].includes(configId) ? false : true,
        DigitalInputVideos: ['BasicDoorbell', 'SingleDoorbellWithAnalytics'].includes(configId) ? false : true,
        VideoDeviceAudio: ['BasicDoorbell', 'SingleDoorbellWithAnalytics'].includes(configId) ? false : true,
        SmartViewForOnboardRecording: ['BasicDoorbell', 'SingleDoorbellWithAnalytics', 'PremiumVideo', 'ProVideo', 273, 179].includes(configId) ? false : true,
        CommercialVideo8Expansion: ['BasicDoorbell', 'ProVideoWithAnalytics', 'PremiumVideo', 'ProVideo', 'SingleDoorbellWithAnalytics', 245, 273, 179].includes(configId) ? false : true,
        CommercialVideo16Expansion: ['BasicDoorbell', 'ProVideoWithAnalytics', 'PremiumVideo', 'ProVideo', 'SingleDoorbellWithAnalytics', 245, 273, 179].includes(configId) ? false : true
      });
      var addonDisplayConfig = Ember.Object.create({
        ImageSensorExtraUploads: [208, 209, 41, 42, 1].includes(this.packageId) ? false : true,
        ImageSensorLimited: [193, 1, 216].includes(this.packageId) ? false : true,
        ImageSensorAlarms: [193, 1, 216].includes(this.packageId) ? false : true,
        ImageSensorPlus: this.packageId === 1 ? false : true,
        PanicButton: [1, 216].includes(this.packageId) ? false : true,
        TwoWayVoice: this.packageId === 216 ? false : true,
        AlarmVisualVerification: this.packageId === 216 ? false : true,
        ZWaveEnergy: [1, 273, 179, 253, 254].includes(this.packageId) ? false : true,
        ZWaveLights: [1, 273, 179, 253, 254].includes(this.packageId) ? false : true,
        ZWaveThermostats: [1, 273, 179, 253, 254].includes(this.packageId) ? false : true,
        ZWaveLocks: [1, 273, 179, 253, 254].includes(this.packageId) ? false : true,
        SmartEnergyPlus: [1, 273, 179, 253, 254].includes(this.packageId) ? false : true,
        LutronIntegration: [1, 273, 179, 253, 254].includes(this.packageId) ? false : true,
        AudioIntegration: [1, 273, 179, 253, 254].includes(this.packageId) ? false : true,
        GarageDoors: [1, 245].includes(this.packageId) ? false : true,
        LiftMasterIntegration: [1, 273, 179, 253, 254].includes(this.packageId) ? false : true,
        WaterManagement: [1, 273, 179, 253, 254].includes(this.packageId) ? false : true,
        WaterManagementPlus: [1, 273, 179, 253, 254].includes(this.packageId) ? false : true,
        SolarIntegration: [1, 273, 179, 253, 254].includes(this.packageId) ? false : true,
        SolarEdgeIntegration: [1, 273, 179, 253, 254].includes(this.packageId) ? false : true,
        EnphaseIntegration: [1, 273, 179, 253, 254].includes(this.packageId) ? false : true,
        IrrigationControl: [1, 273, 179, 253, 254].includes(this.packageId) ? false : true,
        EnterpriseEnergy: [1, 193, 208, 209, 216, 273, 179, 253, 254].includes(this.packageId) ? false : true,
        VoiceNotificationsForAlarms: this.packageId === 1 ? false : true,
        VoiceNotificationsForMonitoring: this.packageId === 1 ? false : true,
        WeatherToPanel: this.packageId === 1 ? false : true,
        SevereWeatherAlerts: [1, 216].includes(this.packageId) ? false : true,
        UnexpectedActivityAlerts: this.packageId === 1 ? false : true,
        ArmingReports: [1, 193, 208, 209, 216].includes(this.packageId) ? false : true,
        Inactivity: [1, 193, 208, 209, 216].includes(this.packageId) ? false : true,
        ArmingSupervision: [1, 193, 208, 209, 216].includes(this.packageId) ? false : true,
        NormalActivityReports: [1, 193, 208, 209, 216].includes(this.packageId) ? false : true,
        ArmingSchedules: [1, 216, 253, 254].includes(this.packageId) ? false : true,
        EnterpriseNotices: [1, 216, 253, 254].includes(this.packageId) ? false : true,
        RemoteArming: [1, 253, 254].includes(this.packageId) ? false : true,
        UserCodeControl: [1, 253, 254].includes(this.packageId) ? false : true,
        // these two are actually FiveNormalActivitySensors (dropdown)
        sensorActivityMonitoringFor40Sensors: [1, 193, 208, 209, 41, 42, 253, 254].includes(this.packageId) ? false : true,
        sensorActivityMonitoringFor50Sensors: [1, 216, 253, 254].includes(this.packageId) ? false : true,
        additionalSensors: [1, 216, 253, 254].includes(this.packageId) ? false : true,
        EnterpriseSecurityConsole: [1, 193, 208, 209, 216, 273, 179].includes(this.packageId) ? false : true,
        SmarterBusinessTemperatureMonitoring: [1, 193, 208, 209, 216, 41, 253, 254].includes(this.packageId) ? false : true,
        //Has extra logic on the doc I need to figure out
        ExtraSmarterBusinessTemperatureMonitoringSensors: [1, 193, 208, 209, 216, 41, 253, 254].includes(this.packageId) ? false : true,
        //Has extra logic on the doc I need to figure out
        CommercialActivityReports: [1, 193, 208, 209, 216, 273, 179].includes(this.packageId) ? false : true,
        Wellness: [1, 193, 208, 209, 216, 41].includes(this.packageId) ? false : true,
        SixHourSupervision: this.packageId === 216 ? false : true,
        HourlySupervision: this.packageId === 216 ? false : true,
        NESTIntegration: this.customerAccountType === 'Video' ? false : true,
        // AccessControl: [41,42,179,180,245,273,287,253,254,269,270,271].includes(this.packageId) ? true : false,
        // AccessControlDoors: [41,42,179,180,245,273,287,253,254,269,270,271].includes(this.packageId) ? true : false,
        // AccessControl16: [41,42,179,180,245,273,287,253,254,269,270,271].includes(this.packageId) ? true : false,
        // AccessControl32: [41,42,179,180,245,273,287,253,254,269,270,271].includes(this.packageId) ? true : false,
        // AccessControl64: [41,42,179,180,245,273,287,253,254,269,270,271].includes(this.packageId) ? true : false,
        AccessPlanUserManagement: [41, 42, 179, 180, 245, 273, 287, 253, 254, 269, 270, 271].includes(this.packageId) ? true : false
      });

      if (this.customerAccountType === 'EnergyAutomation' && this.panelType === 'NoPanel') {
        Object.keys(addonDisplayConfig).forEach(key => {
          var addon = key === 'sensorActivityMonitoringFor40Sensors' || key === 'sensorActivityMonitoringFor50Sensors' || key === 'additionalSensors' ? 'FiveNormalActivitySensors' : key;

          if (!this.includedAddons.includes(addon)) {
            addonDisplayConfig.set(key, false);
          }
        });
      } // Sections should be hidden if all addons within them are hidden. If you add a new addon be sure to add it to the appropriate check below


      var sectionDisplayConfig = Ember.Object.create({
        monitoringStationSection: this.customerAccountType === 'Video' || this.packageId === 216 || !addonDisplayConfig.PanicButton && !addonDisplayConfig.TwoWayVoice && !addonDisplayConfig.AlarmVisualVerification ? false : true,
        imagesSection: this.customerAccountType === 'Video' || this.packageId === 1 || !addonDisplayConfig.ImageSensorPlus && !addonDisplayConfig.ImageSensorAlarms && !addonDisplayConfig.ImageSensorLimited && !addonDisplayConfig.ImageSensorExtraUploads ? false : true,
        energyManagementAndAutomationSection: [1, 245].includes(this.packageId) || !addonDisplayConfig.WaterManagementPlus && !addonDisplayConfig.WaterManagement && !addonDisplayConfig.ZWaveThermostats && !addonDisplayConfig.SolarEdgeIntegration && !addonDisplayConfig.SolarIntegration && !addonDisplayConfig.NESTIntegration && !addonDisplayConfig.LutronIntegration && !addonDisplayConfig.ZWaveLocks && !addonDisplayConfig.ZWaveEnergy && !addonDisplayConfig.ZWaveLights && !addonDisplayConfig.IrrigationControl && !addonDisplayConfig.GarageDoors && !addonDisplayConfig.EnterpriseEnergy && !addonDisplayConfig.EnphaseIntegration && !addonDisplayConfig.SmartEnergyPlus && !addonDisplayConfig.AudioIntegration ? false : true,
        informationAndAlertsSection: this.customerAccountType === 'Video' || this.packageId === 1 || !addonDisplayConfig.NormalActivityReports && !addonDisplayConfig.ArmingSupervision && !addonDisplayConfig.Inactivity && !addonDisplayConfig.ArmingReports && !addonDisplayConfig.UnexpectedActivityAlerts && !addonDisplayConfig.SevereWeatherAlerts && !addonDisplayConfig.WeatherToPanel && !addonDisplayConfig.VoiceNotificationsForMonitoring && !addonDisplayConfig.VoiceNotificationsForAlarms ? false : true,
        remoteMonitoringSection: [1, 245, 273, 179].includes(this.packageId) || !addonDisplayConfig.CommercialActivityReports && !addonDisplayConfig.SmarterBusinessTemperatureMonitoring && addonDisplayConfig.EnterpriseSecurityConsole && !addonDisplayConfig.additionalSensors && !addonDisplayConfig.sensorActivityMonitoringFor50Sensors && !addonDisplayConfig.sensorActivityMonitoringFor40Sensors && !addonDisplayConfig.UserCodeControl && !addonDisplayConfig.RemoteArming && !addonDisplayConfig.EnterpriseNotices && !addonDisplayConfig.ArmingSchedules ? false : true,
        wellnessSection: this.customerAccountType === 'Video' || [1, 193, 208, 209, 41, 216].includes(this.packageId) || !addonDisplayConfig.Wellness ? false : true,
        wirelessAlarmSection: this.customerAccountType === 'Video' || this.packageId === 216 || !addonDisplayConfig.HourlySupervision && !addonDisplayConfig.SixHourSupervision ? false : true,
        videoSection: this.packageId === 1 || !videoAddOnDisplayConfig.SmartViewForOnboardRecording && !videoAddOnDisplayConfig.DigitalInputVideos && !videoAddOnDisplayConfig.VideoDeviceAudio && !videoAddOnDisplayConfig.DoorbellCameras && !videoAddOnDisplayConfig.TwoFiftyMBExtraVideoStorage && !videoAddOnDisplayConfig.CommercialVideo16Expansion && !videoAddOnDisplayConfig.CommercialVideo8Expansion && !videoAddOnDisplayConfig.video24x7Options ? false : true,
        accessPlanUserManagementSection: addonDisplayConfig.AccessPlanUserManagement,
        smarterAccessControlSection: [41, 42, 179, 180, 245, 273, 287, 253, 254, 269, 270, 271].includes(this.packageId)
      }); // Panel Special Rules

      if (addonDisplayConfig.sensorActivityMonitoringFor40Sensors && addonDisplayConfig.sensorActivityMonitoringFor50Sensors) {
        addonDisplayConfig.set('sensorActivityMonitoringFor40Sensors', false);
      }

      if (this.customerOrCommitmentId === 'customer') {
        if (this.customerAccountType === 'EnergyAutomation') {
          switch (this.panelId) {
            case 'Concord':
              Ember.setProperties(addonDisplayConfig, {
                ArmingSchedules: false,
                EnterpriseNotices: false,
                RemoteArming: false,
                sensorActivityMonitoringFor40Sensors: false,
                sensorActivityMonitoringFor50Sensors: false,
                additionalSensors: false,
                EnterpriseSecurityConsole: false,
                SmarterBusinessTemperatureMonitoring: false,
                ExtraSmarterBusinessTemperatureMonitoringSensors: false,
                CommercialActivityReports: false
              });
              break;

            case 'NX':
            case 'SEMPowerSeries':
              sectionDisplayConfig.set('imagesSection', false);
              Ember.setProperties(addonDisplayConfig, {
                ArmingSchedules: false,
                EnterpriseNotices: false,
                RemoteArming: false,
                sensorActivityMonitoringFor40Sensors: false,
                sensorActivityMonitoringFor50Sensors: false,
                additionalSensors: false,
                EnterpriseSecurityConsole: false,
                SmarterBusinessTemperatureMonitoring: false,
                ExtraSmarterBusinessTemperatureMonitoringSensors: false,
                CommercialActivityReports: false
              });
              break;
          }
        } else if (this.customerAccountType === 'Security') {
          switch (this.panelId) {
            case 'Concord':
              Ember.setProperties(addonDisplayConfig, {
                EnterpriseNotices: false,
                WeatherToPanel: false,
                SevereWeatherAlerts: false
              });
              break;

            case 'SEMPowerSeries':
              sectionDisplayConfig.set('imagesSection', false);
              Ember.setProperties(addonDisplayConfig, {
                EnterpriseNotices: false,
                WeatherToPanel: false
              });
              break;
          }
        }
      } else if (this.customerOrCommitmentId === 'commitment') {
        if (this.customerAccountType === 'EnergyAutomation') {
          switch (this.panelId) {
            case 'Iotega':
              Ember.setProperties(addonDisplayConfig, {
                WeatherToPanel: ['att', 'NotSet', 'verizon'].includes(this.network) ? false : true
              });
              break;

            case 'Concord':
              Ember.setProperties(addonDisplayConfig, {
                ArmingSchedules: false,
                EnterpriseNotices: false,
                RemoteArming: false,
                sensorActivityMonitoringFor40Sensors: false,
                sensorActivityMonitoringFor50Sensors: false,
                additionalSensors: false,
                EnterpriseSecurityConsole: false,
                SmarterBusinessTemperatureMonitoring: false,
                ExtraSmarterBusinessTemperatureMonitoringSensors: false,
                CommercialActivityReports: false
              });
              break;

            case 'NX':
            case 'SEMPowerSeries':
              sectionDisplayConfig.set('imagesSection', false);
              Ember.setProperties(addonDisplayConfig, {
                ArmingSchedules: false,
                EnterpriseNotices: false,
                RemoteArming: false,
                sensorActivityMonitoringFor40Sensors: false,
                sensorActivityMonitoringFor50Sensors: false,
                additionalSensors: false,
                EnterpriseSecurityConsole: false,
                SmarterBusinessTemperatureMonitoring: false,
                ExtraSmarterBusinessTemperatureMonitoringSensors: false,
                CommercialActivityReports: false
              });
              break;
          }
        } else if (this.customerAccountType === 'Security') {
          switch (this.panelId) {
            case 'Iotega':
              Ember.setProperties(addonDisplayConfig, {
                WeatherToPanel: ['att', 'NotSet', 'verizon'].includes(this.network) ? false : true
              });
              break;

            case 'Concord':
              Ember.setProperties(addonDisplayConfig, {
                EnterpriseNotices: false,
                WeatherToPanel: false,
                SevereWeatherAlerts: false
              });
              break;

            case 'Simon':
              sectionDisplayConfig.set('imagesSection', ['att', 'verizon', 'NotSet', 'tmobile'].includes(this.network) ? false : true);
              Ember.setProperties(addonDisplayConfig, {
                WeatherToPanel: ['att', 'verizon', 'NotSet', 'tmobile'].includes(this.network) ? false : true,
                SevereWeatherAlerts: ['att', 'verizon', 'NotSet', 'tmobile'].includes(this.network) ? false : true,
                EnterpriseNotices: ['att', 'verizon', 'NotSet', 'tmobile'].includes(this.network) ? false : true,
                ZWaveEnergy: ['att', 'verizon'].includes(this.network) ? false : true,
                ZWaveLights: ['att', 'verizon'].includes(this.network) ? false : true,
                ZWaveThermostats: ['att', 'verizon'].includes(this.network) ? false : true,
                ZWaveLocks: ['att', 'verizon'].includes(this.network) ? false : true,
                TwoWayVoice: ['att', 'verizon'].includes(this.network) ? false : true
              });
              break;

            case 'SEMPowerSeries':
              sectionDisplayConfig.set('imagesSection', false);
              Ember.setProperties(addonDisplayConfig, {
                EnterpriseNotices: false,
                WeatherToPanel: false
              });
              break;

            case 'NX':
              sectionDisplayConfig.set('imagesSection', ['att', 'verizon'].includes(this.network) ? false : true);
              Ember.setProperties(addonDisplayConfig, {
                WeatherToPanel: ['att', 'verizon'].includes(this.network) ? false : true,
                SevereWeatherAlerts: ['att', 'verizon'].includes(this.network) ? false : true,
                EnterpriseNotices: ['att', 'verizon'].includes(this.network) ? false : true
              });
              break;
          }
        }
      }

      return _objectSpread(_objectSpread(_objectSpread({}, sectionDisplayConfig), addonDisplayConfig), videoAddOnDisplayConfig);
    }),

    get allAccessControlBundleOptionsRegardlessOfSelectedAccessControl() {
      var options = [];
      this.accessControlOptions.forEach(option => {
        if (option.value !== undefined) {
          options = options.concat(this.getAccessControlBundleOptionsBySelectedAccesControl(option.value));
        }
      });
      return options;
    },

    removeAddOn(addOns, addOn) {
      var addOnArray = this.addOnArray;

      if (addOnArray[addOn]) {
        addOnArray.set(addOn, false);
        addOns.removeObject(addOn);
      }
    },

    decrementAddOns(addOnToDecrement, currCount, decrementTo) {
      for (var i = currCount; i > decrementTo; --i) {
        var index = this.currentAddOns.indexOf(addOnToDecrement);
        this.currentAddOns.splice(index, 1);
      }
    },

    getAccessControlBundleOptionsBySelectedAccesControl(selectedAccessControl) {
      var accessControlPrefix = 'accessControl' + (selectedAccessControl === 'SmarterAccessControlPlus' ? 'Plus' : '');
      return [{
        value: undefined,
        name: '0 ' + this.intl.t('pages.customers.system.interactiveServicesProvider.addOns.smarterAccessControl.doors')
      }, {
        value: accessControlPrefix.capitalize() + '16',
        name: this.intl.t("pages.customers.system.interactiveServicesProvider.addOns.smarterAccessControl.".concat(accessControlPrefix, "WithValue"), {
          value: 16
        })
      }, {
        value: accessControlPrefix.capitalize() + '32',
        name: this.intl.t("pages.customers.system.interactiveServicesProvider.addOns.smarterAccessControl.".concat(accessControlPrefix, "WithValue"), {
          value: 32
        })
      }, {
        value: accessControlPrefix.capitalize() + '64',
        name: this.intl.t("pages.customers.system.interactiveServicesProvider.addOns.smarterAccessControl.".concat(accessControlPrefix, "WithValue"), {
          value: 64
        })
      }];
    },

    actions: {
      updateAddOns(addOn, removeTemplate = true) {
        var addOns = this.currentAddOns;

        if (removeTemplate) {
          this.set('template', null);
        }

        switch (addOn) {
          case 'videoPackage':
            this.videoPackageOptions.forEach(packageOption => {
              if (addOns.includes(packageOption.value)) {
                addOns.removeObject(packageOption.value);
              }
            });
            addOns.pushObject(this.selectedVideoPackage);
            break;

          case 'accessControl':
            this.accessControlOptions.forEach(option => {
              if (addOns.includes(option.value)) {
                addOns.removeObject(option.value);
              }
            });
            addOns.pushObject(this.selectedAccessControl);
            break;

          case 'accessControlBundle':
            this.allAccessControlBundleOptionsRegardlessOfSelectedAccessControl.forEach(option => {
              if (addOns.includes(option.value)) {
                addOns.removeObject(option.value);
              }
            });
            addOns.pushObject(this.selectedAccessControlBundle);
            break;

          case 'LutronIntegration':
            if (!addOns.includes('LutronIntegration')) {
              addOns.pushObject('LutronIntegration');

              if (!addOns.includes('ZWaveLights') && !addOns.includes('ZWaveEnergy')) {
                addOns.pushObject('ZWaveLights');
              }
            } else {
              addOns.removeObject('LutronIntegration');
            }

            break;

          case 'LiftMasterIntegration':
            if (!addOns.includes('LiftMasterIntegration')) {
              addOns.pushObject('LiftMasterIntegration');

              if (!addOns.includes('GarageDoors')) {
                addOns.pushObject('GarageDoors');
              }
            } else {
              addOns.removeObject('LiftMasterIntegration');
            }

            break;

          case 'WaterManagementPlus':
            if (!addOns.includes('WaterManagementPlus')) {
              addOns.pushObject('WaterManagementPlus');

              if (!addOns.includes('WaterManagement')) {
                addOns.pushObject('WaterManagement');
              }
            } else {
              addOns.removeObject('WaterManagementPlus');
            }

            break;

          case 'SolarEdgeIntegration':
            if (!addOns.includes('SolarEdgeIntegration')) {
              addOns.pushObject('SolarEdgeIntegration');

              if (addOns.includes('EnphaseIntegration')) {
                addOns.removeObject('EnphaseIntegration');
              }

              if (!addOns.includes('SolarIntegration')) {
                addOns.pushObject('SolarIntegration');
              }
            } else {
              addOns.removeObject('SolarEdgeIntegration');
            }

            break;

          case 'EnphaseIntegration':
            if (!addOns.includes('EnphaseIntegration')) {
              addOns.pushObject('EnphaseIntegration');

              if (addOns.includes('SolarEdgeIntegration')) {
                addOns.removeObject('SolarEdgeIntegration');
              }

              if (!addOns.includes('SolarIntegration')) {
                addOns.pushObject('SolarIntegration');
              }
            } else {
              addOns.removeObject('EnphaseIntegration');
            }

            break;

          default:
            if (!addOns.includes(addOn)) {
              addOns.pushObject(addOn);
            } else {
              addOns.removeObject(addOn);
            }

            break;
        }
      },

      updateDropdownAddOns(addOn, value, removeTemplate = true) {
        var addOnArray = this.addOnArray;
        var newAddOnCount = value ? value.value : 0;
        var currAddOnCount = this.getInAddOnArrayCount(addOn);

        if (addOn === 'AccessControlDoors' || addOn === 'AccessControlPlusDoors') {
          var addOnToDecrement = addOn === 'AccessControlDoors' ? 'AccessControlPlusDoors' : 'AccessControlDoors';
          this.decrementAddOns(addOnToDecrement, this.getInAddOnArrayCount(addOnToDecrement), 0);
        }

        if (removeTemplate) {
          this.set('template', null);
        }

        addOnArray.set(addOn, newAddOnCount);

        if (newAddOnCount < currAddOnCount) {
          this.decrementAddOns(addOn, currAddOnCount, newAddOnCount);
        } else {
          for (var i = currAddOnCount; i < newAddOnCount; ++i) {
            this.currentAddOns.pushObject(addOn);
          }
        }
      },

      resetVideoMonitoringAddons() {
        var videoDropdowns = ['Video24x7PerSVR', 'CommercialVideo8Expansion', 'CommercialVideo16Expansion', 'TwoFiftyMBExtraVideoStorage'];
        var videoAddOns = ['DoorbellCameras', 'VideoDeviceAudio', 'DigitalInputVideos', 'SmartViewForOnboardRecording'];
        videoDropdowns.forEach(dropdown => {
          this.actions.updateDropdownAddOns.call(this, dropdown, {
            value: 0
          });
        });
        videoAddOns.forEach(addOn => {
          if (!(addOn in this.addOnDisplayConfig && this.addOnDisplayConfig[addOn])) {
            this.removeAddOn(this.addOns, addOn);
          }
        });
      }

    }
  });

  _exports.default = _default;
});